import React, { useState } from "react"
import styled from "styled-components"
import { grayBackground } from "mill/utils/colors"
import zIndex from "shared/utils/zIndexLevels"
import tooltip from "mill/components/Tooltip/info.png"
import tooltipHover from "mill/components/Tooltip/info-hover.png"

const PassThrough = styled.div`
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.base + 2};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.2s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 2rem 4rem;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.3em;
  z-index: ${zIndex.base + 1};
  opacity: 0;
`
const Link = styled.a`
  cursor: pointer;
  pointer-events: auto;
  padding: 3rem 3rem 2rem 2rem;
  z-index: ${zIndex.base + 3};
  border-radius: 3px;
  position: absolute;
  top: 0rem;
  right: 0rem;
  display: block;
  width: 2rem;
  height: 2rem;

  ${props =>
    props.active &&
    `
    z-index: ${zIndex.base + 4};
  `}

  &:hover + .padded-hover-overlay {
    opacity: 1;
  }
`

const Icon = styled.span`
  margin-top: -2rem;
  border-radius: 3px;
  display: block;
  width: 2rem;
  height: 2rem;
  background-color: ${grayBackground};
  background-image: url(${tooltip});
  background-position: center center;
  background-size: 4px 9px;
  transition: 0.2s all ease-in;

  ${props =>
    props.active &&
    `
    background-color: ${props.theme.colors.primary};
    background-image: url(${tooltipHover});
    z-index: ${zIndex.base + 4};
  `}
`

const HelpOverlay = props => {
  const [hovered, setHovered] = useState(false)

  const handleEnter = () => {
    setHovered(true)
  }

  const handleLeave = () => {
    setHovered(false)
  }

  return (
    <PassThrough>
      <Link
        active={hovered}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}>
        <Icon
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
          active={hovered}
          className="padded-hover-img"
        />
      </Link>
      {hovered && (
        <Overlay className="padded-hover-overlay">{props.helpText}</Overlay>
      )}
    </PassThrough>
  )
}

export default HelpOverlay
