import styled from "styled-components"
import { lightGray } from "../../utils/colors"
import zIndex from "shared/utils/zIndexLevels"

export const Label = styled.span`
  position: relative;
  top: 2px;
`

export const Card = styled.div`
  background: white;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  text-align: left;
  padding: 1.5rem 2rem;
  color: #909090;
  position: relative;
  width: 100%;

  &:hover .hover {
    transition: opacity 0.2s ease-in;
    opacity: 1;
  }

  ${props => props.margin && `margin: ${props.margin};`} ${props =>
    props.alternate &&
    `
    border: 3px solid ${lightGray};
    border-radius: 3px;
  `};
`

export const CardInner = styled.div`
  display: flex;
`

export const Description = styled.em`
  font-size: 1.5rem;
  line-height: 1.3em;
  font-style: normal;
  display: block;
`

export const IconWrap = styled.div`
  text-align: center;
  font-size: 5rem;
  line-height: 7rem;
  width: ${props => (props.extra ? `8rem` : `6rem`)};
  max-width: ${props => (props.extra ? `8rem` : `6rem`)};
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
`

export const IEWrapFix = styled.span`
  margin-right: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: ${props => props.theme.colors.primary};
    flex: 1 0 auto;
  }
`

export const Statistic = styled.div``

export const Stat = styled.strong`
  font-size: 3rem;
  line-height: 1em;
  font-weight: 700;
  letter-spacing: 2px;
  display: block;
  margin-top: 0.8rem;
`

export const Denominator = styled.div`
  display: inline-block;
  font-weight: normal;
  opacity: 0.8;
  position: relative;
  top: 2px;
`

export const DenominatorSplitter = styled.span``

export const LoadMessageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.85);
  z-index: ${zIndex.base + 2};
  font-size: 1.5rem;
  font-weight: bold;
`
