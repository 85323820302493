import React from "react"
import Odometer from "mill/components/Odometer"
import { string, oneOfType, number } from "prop-types"
import HelpOverlay from "mill/components/HelpOverlay"

import {
  Card,
  CardInner,
  Label,
  Stat,
  Statistic,
  Description,
  IconWrap,
  IEWrapFix,
  Denominator,
  DenominatorSplitter,
  LoadMessageOverlay
} from "./styles"

const Icon = props => {
  return <i className={`fa fa-${props.name}`} />
}

const StatisticCard = props => {
  const {
    label,
    stringyStatistic,
    statistic,
    rounded,
    denominator,
    description
  } = props
  const formattedStatistic = rounded ? Math.round(statistic) : statistic
  const cardLabel = () => {
    if (label) {
      return <Label>{label}</Label>
    }
    return null
  }

  const cardNumber = () => {
    return (
      <div style={{ display: "inline-block" }}>
        <Odometer to={formattedStatistic} />
        {denominator !== undefined && (
          <Denominator>
            <DenominatorSplitter>/</DenominatorSplitter>
            <span className={"odometer"}>{denominator}</span>
          </Denominator>
        )}
      </div>
    )
  }

  const stringyStat = () => {
    return (
      <div style={{ display: "block", lineHeight: "1.2em" }}>
        {stringyStatistic}
      </div>
    )
  }

  const loadMessageOverlay = () => {
    return <LoadMessageOverlay>{props.loadMessage}</LoadMessageOverlay>
  }

  return (
    <Card
      alternate={props.alternate}
      margin={props.margin}
      data-qa-statistic={`${stringyStatistic ? stringyStatistic : formattedStatistic}${
        description ? ` ${description}` : ""
      }`}
      data-qa={props["data-qa"]}>
      {!props.loaded && props.loadMessage && loadMessageOverlay()}
      {props.tooltip && <HelpOverlay helpText={props.tooltip} />}
      <CardInner>
        {props.icon && (
          <IconWrap extra>
            <IEWrapFix>
              <Icon name={props.icon} />
            </IEWrapFix>
          </IconWrap>
        )}
        <Statistic>
          <Stat>
            {stringyStatistic ? stringyStat() : cardNumber()}
            {cardLabel()}
          </Stat>
          <Description> {description} </Description>
        </Statistic>
      </CardInner>
    </Card>
  )
}

StatisticCard.propTypes = {
  description: string.isRequired,
  statistic: oneOfType([number]),
  label: string,
  tooltip: string,
  tooltipPosition: string
}

StatisticCard.defaultProps = {
  tooltipPosition: "left",
  margin: "1.5rem 0 0 0"
}

export default StatisticCard
